.btn-grid button{
    border: 1px solid #da291c !important;
    width: 100%;
}

.btn-grid button:hover{
    background-color: #da291c;
    color: white;
}

.btn-grid .enable{
    color: #da291c;
}

.btn-grid .disable{

}



.answerselected{
    background-color: #da291c !important;
    color: white;
}

.answer:hover{
    background-color: #da291c !important;
    color: white;

}
