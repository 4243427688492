.form-check-input[type=checkbox]{
    border-color: red !important;
}
.checkbox-aligned{
    padding-left: 0.5rem;
}

.user-form  input[type=text],input[type=email],input[type=number],select {
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid #a1a1a1 !important;
    font-size: 16px !important;
    margin: 0;
}

.user-form label{
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 100%;
}

.user-form .form-control:focus,.form-select:focus{
    box-shadow: none !important;
    border-bottom: 1px solid #a1a1a1 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
