.home {
    font-family: 'Inter', 'serif';
    padding-top: 55px;
}

.main-banner {
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("../../assets/images/home/home_image.jpg");

}

.main-banner .card {
    border: none !important;
}

.main-banner .card i {
    align-items: center;
    height: 100%;
    display: grid;
}

.main-banner .card .btn-icon {
    border-radius: 10px;
    height: 100%;
}

.main-banner .card .bg-purple {
    background-color: #9063CD;
}

.main-banner .card .bg-gray {
    background-color: #9EB3CA;
}

.main-banner .card .bg-bluelight-1 {
    background-color: #0081E9;
}


.main-banner .card .bg-bluelight {
    background-color: #00D0D5;
}

.main-banner .animation-home {
    height: 100%;
    width: 100%;
    position: absolute;
    max-height: 800px;
}

.main-banner .animation-home .animation-one {
    position: absolute;
    width: calc(10% - 30px);
    height: 15px;
    z-index: 2;
    top: 15%;
    background-color: red;

}

.home .content-2 .video-content {
    z-index: -1;
}

.home .content-2 video {
    position: relative;
    top: 0%;
    left: 0;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;
}

.main-banner .animation-home .animation-two {
    position: absolute;
    width: calc(15% - 10px);
    height: 15px;
    z-index: 2;
    bottom: 1rem;
    left: calc(99% - calc(15% - 10px));
    background-color: red;

}

.main-banner .animation-home .animation-three {
    position: absolute;
    width: calc(10% - 30px);
    height: 15px;
    z-index: 2;
    background-color: #9887CF;
    top: 15%;
    left: calc(99% - calc(10% - 30px));
}

.main-banner .animation-home .animation-four {
    position: absolute;
    width: calc(10% - 30px);
    height: 250px;
    z-index: 2;
    top: calc(15% + 30px);
    left: calc(99% - calc(10% - 30px));
}

.main-banner .animation-home .animation-four .sa-1 {
    background-color: #9887CF;
    width: 100%;
    height: 20%;
}

.main-banner .animation-home .animation-four .sa-2 {
    background-color: #471EA1;
    width: 100%;
    height: 10%;
}

.main-banner .animation-home .animation-four .sa-3 {
    background-color: #7A52C3;
    width: 100%;
    height: 60%;
}

.main-banner .animation-home .animation-five {
    position: absolute;
    width: calc(13% + 10px);
    height: 250px;
    z-index: 1;

    bottom: 0px;
    left: calc(1%);
}

.main-banner .animation-home .animation-five .sa-1 {
    background-color: #268FE5;
    border-top-left-radius: 80px;
    height: 90%;
    width: 100%;
}

.main-banner .animation-home .animation-five .sa-2 {
    background-color: #0049D3;
    height: 10%;
    width: 100%;
}

.main-banner .animation-home .animation-six {
    position: absolute;
    width: calc(13% + 10px);
    height: 15px;
    z-index: 2;
    background-color: #268FE5;
    top: calc(30% + 10px);
    left: calc(1%);
}

.coverimage {
    min-height: 600px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    background-position: center;

}

.main-banner .text-div {
    padding-top: 40px;
}


.main-banner .card-text button {
    width: 100%;
    height: 60px;
}

.main-banner .card-text button .row {
    height: 100%;
}

.main-banner .card-image:hover .btn-icon {
    transform: translateX(3%);
}

.main-banner .card-image .row {
    border-radius: 10px;
}


.main-banner .card-image img {
    margin: auto;
    display: block;
}



.content-2 {
    min-height: 650px;
}

.content-2 .title {
    font-size: 2.6vw;
}


.content-2 .description {
    display: flex;
    min-height: 350px;
}



.content-2 .div-bug {
    margin-top: 20px;
    min-height: 350px;
}

.content-2 .full-content {
    height: 100%;
}
.content-2 .bug-image-lg{
    border: 1px solid #C8C8C8;
}
.content-2 .animation-image {
    border: 1px solid #C8C8C8;


}

.content-2 .animation-image img{
    display: block;
    transform:rotate(-30deg);
    
}
.content-2 .static-image-lg {
    border: 1px solid #C8C8C8;
    left: calc(40% - 30px);
    top: 50%;
    width: fit-content;
}

.content-2 .static-image-lg img {
   min-width: 180px
}


.content-2 .static-image {
    border: 1px solid #C8C8C8;
}

.content-2 .static-image  img{
   display: block;
}

.content-3 {
    
}

.content-3 .icon {
    cursor: pointer;

}

.content-3 .icon:hover {
    opacity: 0.5;
}

.content-3 .world-animation {
   position: absolute;
   margin-top: 16%;
   z-index: -1;
   max-width: 100%;
   overflow: hidden;
}



.poopupSVG {
    width: 200px;
    background-color: white;
    border-radius: 6px;
    position: fixed;
    display: none;
    font-size: small;
    z-index: 1;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 10px 30px 0px #00000026;
    padding: 5px 5px;
    max-height: 120px;
}


.content-3 .animated-gif {
    background-image: url('../../../public/gif-ezgif.com-crop.gif');
    width: 100%;
    height: 235px;
    background-repeat: no-repeat;
    background-position-x: center;
    position: relative;
    top: -221px;
    z-index: 2;
}



.content-4 {
    padding: 84px 0px;

}



.content-4 .title {
    padding: 60px;
    display: block;
    color: white;

}

.content-4 .title .text {
    width: 70%;
    display: block;
    margin: auto;
}

.content-4 .cards .card {
    --bs-border-opacity: 0.2;
    border-color: #3030301c !important;;
}




.content-5 {

    padding-bottom: 80px;

}

.content4-5{
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("../../assets/images/home/bg-homepage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-lightred {
    background-color: #fbd9d9;
}


.content-5 .card {
    --bs-border-opacity: 0.2;
}

.content-5 .card a {
    width: 100%;
    /* font-size: 13px; */
}





.content-6 .card {
    background-color: #F0F0F0;
}



.content-5 .bg-transparent-1 {
    background-color: #ffffff85;
    .card-footer{
        border-color: #e6000000 !important;
        background-color: transparent;
    }
}

.content-6 .card .list i {
    font-size: 3.2vw;
}


.content-5 .cont {
    left: calc(50% - 200px);
    position: relative;
}

.content-5 .cont img {
    position: absolute;
    top: 0%;
    left: 0%;
}




.content-7 {
    max-height: 500px;
    overflow: hidden;

}

.content-7 img {
    opacity: 1;
    filter: brightness(100%);

}

.content-2 .div-bug .bug-image-lg img {
    animation-name: walk-screen;
    animation-timeline: view();
    animation-range: contain;
    animation-timing-function: linear;
    animation-fill-mode: both;

}


 @keyframes walk-screen {

    0% {
        transform: translate(0px, 0px) rotate(-30deg);

    }



    45% {
        transform: translate(0px, 0px) rotate(-30deg);
    }



    60% {
        transform: translate(-400px, -192px);
    }

    70% {
        transform: translate(-315px, -192px) rotate(100deg);

    }

    85% {
        transform: translate(-290px, -192px) rotate(180deg);
    }

    87% {
        opacity: 1;
    }

    90% {
        opacity: .5;

    }

    100% {
        transform: translate(0px, 535px) rotate(180deg) scale(0.8);
        opacity: 0;
        visibility: hidden;

    }

} 



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
    .content-2 .div-bug .bug-image img {
        animation: none
    }

    .content-3  .world-animation{
        position: static;
        height: 9.5rem;
    }

  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .content-2 .div-bug .bug-image img {
        animation: none
    }

  

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 991px) {

    

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1440px) {}

@media screen and (min-width: 1900px) {
    .content-2 .div-bug .bug-image-lg img{
        min-height: 300px;
    }
    .content-2 .static-image-lg img {
        min-height: 300px;
    }

}